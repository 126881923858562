import type { QueryArg } from './types';

export const getMethod = (arg: QueryArg) => {
  const fallback = 'GET';

  if (typeof arg === 'string') return fallback;

  return arg?.method ?? fallback;
};

export const getRelativeUrl = (arg: QueryArg) => {
  if (typeof arg === 'string') return arg;

  return arg.url;
};
