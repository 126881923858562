import createApiClient from 'services/CreateApiClient';

import { savePagination } from 'dux/pagination/slice';

import type { FetchJson } from './types';
import { getMethod, getRelativeUrl } from './utils';

const fetchJson: FetchJson =
  ({ baseUrl }) =>
  async (args, api) => {
    const callApi = createApiClient(api.dispatch, getMethod(args));

    try {
      const response = await callApi(`${baseUrl}${getRelativeUrl(args)}`);
      const pages = response?.pages;

      if (pages) {
        api.dispatch(
          savePagination({
            pages,
            callType: api.type,
            endpoint: api.endpoint,
            arg: getRelativeUrl(args),
          })
        );
      }

      const payload = await response.json();
      return { data: payload };
    } catch (error) {
      return { error };
    }
  };

export default fetchJson;
